import React, { useState, handlelick, active, useContext } from "react";
import "./Sidebar.css";
import { Link, useLocation } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import Accordion from "react-bootstrap/Accordion";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faMoneyCheck, fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { Context } from "../../utils/context";
import { faAffiliatetheme } from "@fortawesome/free-brands-svg-icons";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import { IDS } from "../../utils/common";
library.add(fas);

const Sidebar = () => {
  const { isSidebarOpen, setSidebarOpen, isAllow } = useContext(Context);
  const location = useLocation();

  const [isOpen, setIsOpen] = useState(true);
  const [showItemNames, setShowItemNames] = useState(true);
  const [headerText, setHeaderText] = useState(location.pathname);

  useEffect(() => {
    setHeaderText(location.pathname);
  });

  const [active, setActive] = useState("0");
  const handleClick = (eventKey) => {
    if (active === eventKey) {
      setActive(null);
    } else {
      setActive(eventKey);
      // setSidebarOpen(true);
    }
  };

  const isActive = (path) => {
    return location.pathname.includes(path) ? "active block" : "none";
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <section className="sidebar">
        <div className="desktop-view-sidebar">
          <div className="top_section">
            <img
              className="logo ms-2"
              src={process.env.PUBLIC_URL + "/BoomerBucklogo.png"}
            />
          </div>
          <ul className="list-unstyled ps-0">
            {/* ***************************************************Dashboard*************************************************************** */}

            <Accordion
              // defaultActiveKey="0"
              className="mb-1"
              data-bs-toggle="collapse"
              data-bs-target="#home-collapse"
              aria-expanded="false"
            >
              <Accordion.Item
                eventKey="0"
                className="mb-1"
                onClick={() => handleClick("0")}
              >
                <Accordion.Header>
                  <div className=" me-3">
                    <FontAwesomeIcon
                      icon="fa-solid fa-house"
                      onClick={() => setIsOpen(!isOpen)}
                    />
                  </div>
                  <p
                    className="sidebar_txt"
                    style={{ display: isSidebarOpen ? "block" : "none" }}
                    onClick={() => setSidebarOpen(true)}
                  >
                    Dashboard
                  </p>

                  {/* </button> */}
                </Accordion.Header>
                <Accordion.Body className="p-0">
                  <ul
                    className={`accordion_list list-unstyled fw-normal pb-1 small ${
                      isSidebarOpen ? "arrowshow" : "arrowHide"
                    }`}
                    style={{
                      display: isSidebarOpen ? "block" : "none",
                    }}
                  >
                    <Link
                      to="/advanceDashboard"
                      className="text-decoration-none"
                    >
                      <li className={isActive("/advanceDashboard")}>
                        <p key={2} className="insideDropdown_list" id={"2"}>
                          Advance Dashboard
                        </p>
                      </li>
                    </Link>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
              {/* *********************************************************Dashboard Ends*************************************************************** */}

              {/* ************************************************************  Orders ************************************************************* */}
              {isAllow?.includes(107) || isAllow?.includes(113) ? (
                <Accordion.Item
                  eventKey="1"
                  className="mb-1"
                  onClick={() => handleClick("1")}
                >
                  <Accordion.Header>
                    <div className="me-3">
                      <FontAwesomeIcon icon="fa fa-shopping-cart" />
                    </div>
                    <p
                      className="sidebar_txt"
                      style={{ display: isSidebarOpen ? "block" : "none" }}
                    >
                      Orders
                    </p>
                  </Accordion.Header>
                  <Accordion.Body
                    className="p-0"
                    style={{
                      display: isOpen ? "block" : "none",
                    }}
                  >
                    <ul className="accordion_list list-unstyled fw-normal pb-1 small">
                      {isAllow?.includes(107) ? (
                        <Link
                          to="/order-managements/orders"
                          className=" text-decoration-none  "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes(
                                    "/order-managements/orders"
                                  )
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Order
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}
                      {isAllow?.includes(113) ? (
                        <Link
                          to="/order-managements/order/return"
                          className=" text-decoration-none  "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes(
                                    "/order-managements/order/return"
                                  )
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Returned Order
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              ) : (
                <></>
              )}

              {/* ************************************************************  Orders End ************************************************************* */}

              {/* ************************************************************  Categories ************************************************************* */}
              {isAllow?.includes(29) ||
              isAllow?.includes(33) ||
              isAllow?.includes(37) ? (
                <Accordion.Item
                  eventKey="2"
                  className="mb-1"
                  onClick={() => handleClick("2")}
                >
                  <Accordion.Header>
                    <div className="me-3">
                      <FontAwesomeIcon icon="fa fa-bullseye" />
                    </div>
                    <p
                      className="sidebar_txt"
                      style={{ display: isSidebarOpen ? "block" : "none" }}
                    >
                      Categories
                    </p>
                  </Accordion.Header>
                  <Accordion.Body
                    className="p-0"
                    style={{
                      display: isOpen ? "block" : "none",
                    }}
                  >
                    <ul className="accordion_list list-unstyled fw-normal pb-1 small">
                      {isAllow?.includes(29) ? (
                        <Link
                          to="/settings/category"
                          className=" text-decoration-none  "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/settings/category")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Category
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}
                      {isAllow?.includes(33) ? (
                        <Link
                          to="/settings/sub-category"
                          className=" text-decoration-none  "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/settings/sub-category")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Sub Category
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}

                      {isAllow?.includes(29) ? (
                        <Link
                          to="/settings/category-for-seller"
                          className=" text-decoration-none  "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes(
                                    "/settings/category-for-seller"
                                  )
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Category For Seller
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}

                      {/* {isAllow?.includes(37) ? (
                        <Link
                          to="/settings/child-sub-category"
                          className=" text-decoration-none  "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes(
                                  "/settings/child-sub-category"
                                )
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Child Sub Category
                            </p>
                          </li>
                        </Link>) : (
                        <></>
                      )} */}
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              ) : (
                <></>
              )}
              {/* ************************************************************  Categories End ************************************************************* */}

              {/* ************************************************************  Products ************************************************************* */}

              {isAllow?.includes(87) ? (
                <Accordion.Item
                  eventKey="3"
                  className="mb-1"
                  onClick={() => handleClick("3")}
                >
                  <Accordion.Header>
                    <div className="me-3">
                      <FontAwesomeIcon
                        icon="fa fa-cubes"
                        // onClick={() => setIsOpen(!isOpen)}
                      />
                    </div>
                    <p
                      className="sidebar_txt"
                      style={{ display: isSidebarOpen ? "block" : "none" }}
                    >
                      Products
                    </p>
                  </Accordion.Header>
                  <Accordion.Body
                    className="p-0"
                    style={{
                      display: isOpen ? "block" : "none",
                    }}
                  >
                    <ul
                      className="accordion_list list-unstyled fw-normal pb-1 small"
                      // style={{
                      //   display: isSidebarOpen ? "block" : "none",
                      // }}
                    >
                      <Link
                        to="product"
                        className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                      >
                        <li
                          className={
                            isOpen
                              ? headerText.includes("/product")
                                ? "active block"
                                : "none"
                              : "none"
                          }
                        >
                          <p
                            className="insideDropdown_list"
                            style={{ display: isOpen ? "block" : "none" }}
                          >
                            Product
                          </p>
                        </li>
                      </Link>
                      {/* ) : (
                    <></>
                  )} */}

                      {/* {isAllow?.includes(5) ||
                  isAllow?.includes(6) ||
                  isAllow?.includes(7) ||
                  isAllow?.includes(8) ? ( */}
                      {/* <Link
                    to="/employee/employee-details"
                    className=" text-decoration-none  "
                  // className="text-decoration-none rounded "
                  >
                    <li
                      className={
                        isOpen
                          ? headerText.includes("/employee/employee-details")
                            ? "active block"
                            : "none"
                          : "none"
                      }
                    >
                      <p
                        className="insideDropdown_list"
                        style={{ display: isOpen ? "block" : "none" }}
                      >
                        Employee Details
                      </p>
                    </li>
                  </Link> */}
                      {/* ) : (
                    <></>
                  )} */}

                      {isAllow?.includes(89) ? (
                        <Link
                          to="/product-requests"
                          className=" text-decoration-none  "
                          // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/product-requests")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Product Requests
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}

                      {isAllow?.includes(291) ? (
                        <Link
                          to="/product-review"
                          className=" text-decoration-none  "
                          // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/product-review")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Product Review
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              ) : (
                <></>
              )}

              {isAllow?.includes(53) || isAllow?.includes(57) ? (
                <Accordion.Item
                  eventKey="26"
                  className="mb-1"
                  onClick={() => handleClick("26")}
                >
                  <Accordion.Header>
                    <div className="me-3">
                      <FontAwesomeIcon icon={faMoneyCheck} />
                    </div>
                    <p
                      className="sidebar_txt"
                      style={{ display: isSidebarOpen ? "block" : "none" }}
                    >
                      Taxation
                    </p>
                  </Accordion.Header>
                  <Accordion.Body
                    className="p-0"
                    style={{
                      display: isOpen ? "block" : "none",
                    }}
                  >
                    <ul
                      className="accordion_list list-unstyled fw-normal pb-1 small"
                      // style={{
                      //   display: isSidebarOpen ? "block" : "none",
                      // }}
                    >
                      {isAllow?.includes(53) ? (
                        <Link
                          to="/settings/tax/tax-type"
                          className=" text-decoration-none  "
                          // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/settings/tax/tax-type")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Tax Types
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}
                      {isAllow?.includes(57) ? (
                        <Link
                          to="/settings/tax/tax-percentage"
                          className=" text-decoration-none  "
                          // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes(
                                    "/settings/tax/tax-percentage"
                                  )
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Tax Percentage
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              ) : (
                <></>
              )}

              {/* ************************************************************  Products End ************************************************************* */}

              {/* ************************************************************  Sellers ************************************************************* */}
              {isAllow?.includes(81) ||
              isAllow?.includes(82) ||
              isAllow?.includes(83) ||
              isAllow?.includes(84) ? (
                <Accordion.Item
                  eventKey="4"
                  className="mb-1"
                  onClick={() => handleClick("4")}
                >
                  <Accordion.Header>
                    <div className="me-3">
                      <FontAwesomeIcon
                        icon="fa fa-store"
                        // onClick={() => setIsOpen(!isOpen)}
                      />
                    </div>
                    <p
                      className="sidebar_txt"
                      style={{ display: isSidebarOpen ? "block" : "none" }}
                    >
                      Sellers
                    </p>
                  </Accordion.Header>
                  <Accordion.Body
                    className="p-0"
                    style={{
                      display: isOpen ? "block" : "none",
                    }}
                  >
                    <ul
                      className="accordion_list list-unstyled fw-normal pb-1 small"
                      // style={{
                      //   display: isSidebarOpen ? "block" : "none",
                      // }}
                    >
                      {isAllow?.includes(81) ? (
                        <Link
                          to="/employee/employee-requests"
                          className=" text-decoration-none  "
                          // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes(
                                    "/employee/employee-requests"
                                  )
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Seller Request
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}

                      {/* <Link
                        to="/employee/employee-details"
                        className=" text-decoration-none  "
                      // className="text-decoration-none rounded "
                      >
                        <li
                          className={
                            isOpen
                              ? headerText.includes("/employee/employee-details")
                                ? "active block"
                                : "none"
                              : "none"
                          }
                        >
                          <p
                            className="insideDropdown_list"
                            style={{ display: isOpen ? "block" : "none" }}
                          >
                            Seller Details
                          </p>
                        </li>
                      </Link> */}
                      {isAllow?.includes(82) ? (
                        <Link
                          to="/seller-detail"
                          className=" text-decoration-none  "
                          // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/seller-detail")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Sellers Details
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}

                      {isAllow?.includes(84) ? (
                        <Link
                          to="/employee/employee-rejected"
                          className=" text-decoration-none  "
                          // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes(
                                    "/employee/employee-rejected"
                                  )
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Rejected Requests
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              ) : (
                <></>
              )}

              {/* ************************************************************  Sellers End ************************************************************* */}
              {/* ************************************************************  Sellers ************************************************************* */}
              {isAllow?.includes(286) ||
              isAllow?.includes(287) ||
              isAllow?.includes(288) ||
              isAllow?.includes(290) ||
              isAllow?.includes(289) ? (
                <Accordion.Item
                  eventKey="28"
                  className="mb-1"
                  onClick={() => handleClick("18")}
                >
                  <Accordion.Header>
                    <div className="me-3">
                      <FontAwesomeIcon
                        icon="fa fa-users"
                        // onClick={() => setIsOpen(!isOpen)}
                      />
                    </div>
                    <p
                      className="sidebar_txt"
                      style={{ display: isSidebarOpen ? "block" : "none" }}
                    >
                      Customers
                    </p>
                  </Accordion.Header>
                  <Accordion.Body
                    className="p-0"
                    style={{
                      display: isOpen ? "block" : "none",
                    }}
                  >
                    <ul
                      className="accordion_list list-unstyled fw-normal pb-1 small"
                      // style={{
                      //   display: isSidebarOpen ? "block" : "none",
                      // }}
                    >
                      {isAllow?.includes(286) ? (
                        <Link
                          to="/employee/customer-details"
                          className=" text-decoration-none  "
                          // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes(
                                    "/employee/customer-details"
                                  )
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Customers Details
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}

                      {isAllow?.includes(287) ? (
                        <Link
                          to="/employee/contact-us"
                          className=" text-decoration-none  "
                          // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/employee/contact-us")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Contact Us
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              ) : (
                <></>
              )}

              {isAllow?.includes(290) ? (
                <Accordion.Item
                  eventKey="30"
                  className="mb-1"
                  onClick={() => handleClick("30")}
                >
                  <Accordion.Header>
                    <div className="me-3">
                      <FontAwesomeIcon
                        icon="fa fa-users"
                        // onClick={() => setIsOpen(!isOpen)}
                      />
                    </div>
                    <p
                      className="sidebar_txt"
                      style={{ display: isSidebarOpen ? "block" : "none" }}
                    >
                      Boomerbucks Inquiry
                    </p>
                  </Accordion.Header>
                  <Accordion.Body
                    className="p-0"
                    style={{
                      display: isOpen ? "block" : "none",
                    }}
                  >
                    <ul
                      className="accordion_list list-unstyled fw-normal pb-1 small"
                      // style={{
                      //   display: isSidebarOpen ? "block" : "none",
                      // }}
                    >
                      {isAllow?.includes(290) ? (
                        <Link
                          to="/employee/inquire"
                          className=" text-decoration-none  "
                          // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/employee/inquire")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Boomerbucks Inquiry
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              ) : (
                <></>
              )}

              {/* ************************************************************  Sellers End ************************************************************* */}

              {/* ************************************************************  Customization ************************************************************* */}
              {isAllow?.includes(73) ||
              isAllow?.includes(75) ||
              isAllow?.includes(270) ||
              isAllow?.includes(274) ||
              isAllow?.includes(292) ? (
                <Accordion.Item
                  eventKey="5"
                  className="mb-1"
                  onClick={() => handleClick("5")}
                >
                  <Accordion.Header>
                    <div className="me-3">
                      <FontAwesomeIcon
                        icon="fa-solid fa-sliders"
                        // onClick={() => setIsOpen(!isOpen)}
                      />
                    </div>
                    <p
                      className="sidebar_txt"
                      style={{ display: isSidebarOpen ? "block" : "none" }}
                    >
                      Customization
                    </p>
                  </Accordion.Header>
                  <Accordion.Body
                    className="p-0"
                    style={{
                      display: isOpen ? "block" : "none",
                    }}
                  >
                    <ul
                      className="accordion_list list-unstyled fw-normal pb-1 small"
                      // style={{
                      //   display: isSidebarOpen ? "block" : "none",
                      // }}
                    >
                      {/* {isAllow?.includes(73) ? (
                        <Link
                          to="/settings/customization/web-header"
                          className=" text-decoration-none  "
                          // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes(
                                    "/settings/customization/web-header"
                                  )
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Web Header
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )} */}

                      {isAllow?.includes(77) ||
                      isAllow?.includes(78) ||
                      isAllow?.includes(79) ||
                      isAllow?.includes(80) ? (
                        <Link
                          to="/settings/customization/app-setup"
                          className=" text-decoration-none  "
                          // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes(
                                    "/settings/customization/app-setup"
                                  )
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              App Setup
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}

                      {isAllow?.includes(292) ||
                      isAllow?.includes(293) ||
                      isAllow?.includes(294) ||
                      isAllow?.includes(295) ? (
                        <Link
                          to="/birthday"
                          className=" text-decoration-none  "
                          // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/birthday")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Birthday
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              ) : (
                <></>
              )}
              {/* ************************************************************  Customization End ************************************************************* */}

              {/* ************************************************************  Coupon Codes  ************************************************************* */}
              {isAllow?.includes(61) ? (
                <Accordion.Item
                  eventKey="6"
                  className="mb-1"
                  onClick={() => handleClick("6")}
                >
                  <Accordion.Header>
                    <div className="me-3">
                      <FontAwesomeIcon
                        icon="fa-solid fa-ticket"

                        // onClick={() => setIsOpen(!isOpen)}
                      />
                    </div>
                    <p
                      className="sidebar_txt"
                      style={{ display: isSidebarOpen ? "block" : "none" }}
                    >
                      Coupon Codes
                    </p>
                  </Accordion.Header>
                  <Accordion.Body
                    className="p-0"
                    style={{
                      display: isOpen ? "block" : "none",
                    }}
                  >
                    <ul
                      className="accordion_list list-unstyled fw-normal pb-1 small"
                      // style={{
                      //   display: isSidebarOpen ? "block" : "none",
                      // }}
                    >
                      {isAllow?.includes(61) ? (
                        <Link
                          to="/settings/Coupon-codes"
                          className=" text-decoration-none  "
                          // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/settings/Coupon-codes")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Coupon Codes
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              ) : (
                <></>
              )}
              {/* ************************************************************   Coupon Codes End ************************************************************* */}

              {/* ************************************************************  Customers ************************************************************* */}

              {/* <Accordion.Item
              eventKey="7"
              className="mb-1"
              onClick={() => handleClick("7")}
            >
              <Accordion.Header>
                <div className="me-3">
                  <FontAwesomeIcon
                    icon="fa fa-male"
                  // onClick={() => setIsOpen(!isOpen)}
                  />
                </div>
                <p
                  className="sidebar_txt"
                  style={{ display: isSidebarOpen ? "block" : "none" }}
                >
                  Customers
                </p>
              </Accordion.Header>
              <Accordion.Body
                className="p-0"
                style={{
                  display: isOpen ? "block" : "none",
                }}
              >
                <ul
                  className="accordion_list list-unstyled fw-normal pb-1 small"
                // style={{
                //   display: isSidebarOpen ? "block" : "none",
                // }}
                >
                  <Link
                    to="product"
                    className=" text-decoration-none  "
                  // className="text-decoration-none rounded "
                  >
                    <li
                      className={
                        isOpen
                          ? headerText.includes("/product")
                            ? "active block"
                            : "none"
                          : "none"
                      }
                    >
                      <p
                        className="insideDropdown_list"
                        style={{ display: isOpen ? "block" : "none" }}
                      >
                        Product
                      </p>
                    </li>
                  </Link>




                </ul>
              </Accordion.Body>

            </Accordion.Item> */}

              {/* ************************************************************  Customers End ************************************************************* */}

              {/* ************************************************************  Location ************************************************************* */}
              {isAllow?.includes(9) ||
              isAllow?.includes(13) ||
              isAllow?.includes(17) ||
              isAllow?.includes(21) ? (
                <Accordion.Item
                  eventKey="8"
                  className="mb-1"
                  onClick={() => handleClick("8")}
                >
                  <Accordion.Header>
                    <div className="me-3">
                      <FontAwesomeIcon
                        icon="fa-solid fa-map-location-dot"

                        // onClick={() => setIsOpen(!isOpen)}
                      />
                    </div>
                    <p
                      className="sidebar_txt"
                      style={{ display: isSidebarOpen ? "block" : "none" }}
                    >
                      Location
                    </p>
                  </Accordion.Header>
                  <Accordion.Body
                    className="p-0"
                    style={{
                      display: isOpen ? "block" : "none",
                    }}
                  >
                    <ul
                      className="accordion_list list-unstyled fw-normal pb-1 small"
                      // style={{
                      //   display: isSidebarOpen ? "block" : "none",
                      // }}
                    >
                      {/* {isAllow?.includes(9) ? ( */}
                      {IDS.Country.some((value) => isAllow.includes(value)) && (
                        <Link
                          to="/settings/location/country"
                          className=" text-decoration-none  "
                          // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes(
                                    "/settings/location/country"
                                  )
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Country
                            </p>
                          </li>
                        </Link>
                      )}
                      {/* ) : (
                        <></>
                      )} */}
                      {isAllow?.includes(13) ? (
                        <Link
                          to="/settings/location/state"
                          className=" text-decoration-none  "
                          // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes(
                                    "/settings/location/state"
                                  )
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              State
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}
                      {isAllow?.includes(17) ? (
                        <Link
                          to="/settings/location/city"
                          className=" text-decoration-none  "
                          // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/settings/location/city")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              City
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}
                      {isAllow?.includes(21) ? (
                        <Link
                          to="/settings/location/pincode"
                          className=" text-decoration-none  "
                          // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes(
                                    "/settings/location/pincode"
                                  )
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Pincode
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              ) : (
                <></>
              )}
              {/* ************************************************************  Location End ************************************************************* */}

              {/* ************************************************************  Home Settings  ************************************************************* */}
              {isAllow?.includes(69) ||
              isAllow?.includes(258) ||
              isAllow?.includes(262) ||
              isAllow?.includes(266) ? (
                <Accordion.Item
                  eventKey="9"
                  className="mb-1"
                  onClick={() => handleClick("9")}
                >
                  <Accordion.Header>
                    <div className="me-3">
                      <FontAwesomeIcon
                        icon="fa-solid fa-house-laptop"

                        // onClick={() => setIsOpen(!isOpen)}
                      />
                    </div>
                    <p
                      className="sidebar_txt"
                      style={{ display: isSidebarOpen ? "block" : "none" }}
                    >
                      Home Settings
                    </p>
                  </Accordion.Header>
                  <Accordion.Body
                    className="p-0"
                    style={{
                      display: isOpen ? "block" : "none",
                    }}
                  >
                    <ul
                      className="accordion_list list-unstyled fw-normal pb-1 small"
                      // style={{
                      //   display: isSidebarOpen ? "block" : "none",
                      // }}
                    >
                      {isAllow?.includes(69) ? (
                        <Link
                          to="/settings/customization/home-banner"
                          className=" text-decoration-none  "
                          // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes(
                                    "/settings/customization/home-banner"
                                  )
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Home Banner
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}
                      {isAllow?.includes(258) ? (
                        <Link
                          to="/settings/customization/category-banner"
                          className=" text-decoration-none  "
                          // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes(
                                    "/settings/customization/category-banner"
                                  )
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Category Banner
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}
                      {isAllow?.includes(262) ? (
                        <Link
                          to="/settings/privacy-policy"
                          className=" text-decoration-none  "
                          // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes(
                                    "/settings/privacy-policy"
                                  )
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Privacy Policy
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}
                      {isAllow?.includes(266) ? (
                        <Link
                          to="/settings/terms-and-conditition"
                          className=" text-decoration-none  "
                          // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes(
                                    "/settings/terms-and-conditition"
                                  )
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Terms And Condition
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              ) : (
                <></>
              )}
              {/* ************************************************************   Home Settings End ************************************************************* */}

              {/* ************************************************************  Blogs  ************************************************************* */}
              {isAllow?.includes(65) || isAllow?.includes(278) ? (
                <Accordion.Item
                  eventKey="10"
                  className="mb-1"
                  onClick={() => handleClick("10")}
                >
                  <Accordion.Header>
                    <div className="me-3">
                      <FontAwesomeIcon
                        icon="fa-solid fa-blog"

                        // onClick={() => setIsOpen(!isOpen)}
                      />
                    </div>
                    <p
                      className="sidebar_txt"
                      style={{ display: isSidebarOpen ? "block" : "none" }}
                    >
                      Blogs
                    </p>
                  </Accordion.Header>
                  <Accordion.Body
                    className="p-0"
                    style={{
                      display: isOpen ? "block" : "none",
                    }}
                  >
                    <ul
                      className="accordion_list list-unstyled fw-normal pb-1 small"
                      // style={{
                      //   display: isSidebarOpen ? "block" : "none",
                      // }}
                    >
                      {isAllow?.includes(65) ? (
                        <Link
                          to="/settings/customization/blogs"
                          className=" text-decoration-none  "
                          // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes(
                                    "/settings/customization/blogs"
                                  )
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Blogs
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}

                      {isAllow?.includes(278) ? (
                        <Link
                          to="/settings/customization/blog-video"
                          className=" text-decoration-none  "
                          // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes(
                                    "/settings/customization/blog-video"
                                  )
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Blog Video
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              ) : (
                <></>
              )}
              {/* ************************************************************   Blogs End ************************************************************* */}

              {/* ************************************************************  Brands  ************************************************************* */}
              {isAllow?.includes(49) ? (
                <Accordion.Item
                  eventKey="11"
                  className="mb-1"
                  onClick={() => handleClick("11")}
                >
                  <Accordion.Header>
                    <div className="me-3">
                      <FontAwesomeIcon
                        icon="fa-solid fa-copyright"

                        // onClick={() => setIsOpen(!isOpen)}
                      />
                    </div>
                    <p
                      className="sidebar_txt"
                      style={{ display: isSidebarOpen ? "block" : "none" }}
                    >
                      Brands
                    </p>
                  </Accordion.Header>
                  <Accordion.Body
                    className="p-0"
                    style={{
                      display: isOpen ? "block" : "none",
                    }}
                  >
                    <ul
                      className="accordion_list list-unstyled fw-normal pb-1 small"
                      // style={{
                      //   display: isSidebarOpen ? "block" : "none",
                      // }}
                    >
                      {isAllow?.includes(49) ? (
                        <Link
                          to="/settings/brands"
                          className=" text-decoration-none  "
                          // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/settings/brands")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Brands
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              ) : (
                <></>
              )}
              {/* ************************************************************   Brands End ************************************************************* */}

              {/* ************************************************************  Tax  ************************************************************* */}
              {/* {isAllow?.includes(53) ||
                isAllow?.includes(57) ? (
                <Accordion.Item
                  eventKey="12"
                  className="mb-1"
                  onClick={() => handleClick("12")}
                >
                  <Accordion.Header>
                    <div className="me-3">
                      <FontAwesomeIcon
                        icon="fa-solid fa-money-check-dollar"

                      // onClick={() => setIsOpen(!isOpen)}
                      />
                    </div>
                    <p
                      className="sidebar_txt"
                      style={{ display: isSidebarOpen ? "block" : "none" }}
                    >
                      Tax
                    </p>
                  </Accordion.Header>
                  <Accordion.Body
                    className="p-0"
                    style={{
                      display: isOpen ? "block" : "none",
                    }}
                  >
                    <ul
                      className="accordion_list list-unstyled fw-normal pb-1 small"
                    // style={{
                    //   display: isSidebarOpen ? "block" : "none",
                    // }}
                    >
                      <Link
                        to="/settings/tax/tax-type"
                        className=" text-decoration-none  "
                      // className="text-decoration-none rounded "
                      >
                        <li
                          className={
                            isOpen
                              ? headerText.includes("/settings/tax/tax-type")
                                ? "active block"
                                : "none"
                              : "none"
                          }
                        >
                          <p
                            className="insideDropdown_list"
                            style={{ display: isOpen ? "block" : "none" }}
                          >
                            Tax Types
                          </p>
                        </li>
                      </Link>

                      <Link
                        to="/settings/tax/tax-percentage"
                        className=" text-decoration-none  "
                      // className="text-decoration-none rounded "
                      >
                        <li
                          className={
                            isOpen
                              ? headerText.includes("/settings/tax/tax-percentage")
                                ? "active block"
                                : "none"
                              : "none"
                          }
                        >
                          <p
                            className="insideDropdown_list"
                            style={{ display: isOpen ? "block" : "none" }}
                          >
                            Tax Percentage
                          </p>
                        </li>
                      </Link>

                    </ul>
                  </Accordion.Body>

                </Accordion.Item>
              ) : (
                <></>
              )} */}
              {/* ************************************************************   Tax End ************************************************************* */}

              {/* ************************************************************  Attributes  ************************************************************* */}

              {/* <Accordion.Item
              eventKey="13"
              className="mb-1"
              onClick={() => handleClick("13")}
            >
              <Accordion.Header>
                <div className="me-3">
                  <FontAwesomeIcon
                    icon="fa-solid fa-mattress-pillow"

                  // onClick={() => setIsOpen(!isOpen)}
                  />
                </div>
                <p
                  className="sidebar_txt"
                  style={{ display: isSidebarOpen ? "block" : "none" }}
                >
                  Attributes
                </p>
              </Accordion.Header>
              <Accordion.Body
                className="p-0"
                style={{
                  display: isOpen ? "block" : "none",
                }}
              >
                <ul
                  className="accordion_list list-unstyled fw-normal pb-1 small"
                // style={{
                //   display: isSidebarOpen ? "block" : "none",
                // }}
                >
                  <Link
                    to="/settings/attributes"
                    className=" text-decoration-none  "
                  // className="text-decoration-none rounded "
                  >
                    <li
                      className={
                        isOpen
                          ? headerText.includes("/settings/attributes")
                            ? "active block"
                            : "none"
                          : "none"
                      }
                    >
                      <p
                        className="insideDropdown_list"
                        style={{ display: isOpen ? "block" : "none" }}
                      >
                        Attribute
                      </p>
                    </li>
                  </Link>

                  <Link
                    to="/settings/sub-attributes"
                    className=" text-decoration-none  "
                  // className="text-decoration-none rounded "
                  >
                    <li
                      className={
                        isOpen
                          ? headerText.includes("/settings/sub-attributes")
                            ? "active block"
                            : "none"
                          : "none"
                      }
                    >
                      <p
                        className="insideDropdown_list"
                        style={{ display: isOpen ? "block" : "none" }}
                      >
                        Sub Attribute
                      </p>
                    </li>
                  </Link>

                </ul>
              </Accordion.Body>

            </Accordion.Item> */}

              {/* ************************************************************   Attributes End ************************************************************* */}

              {/* ************************************************************  FAQ  ************************************************************* */}
              {isAllow?.includes(41) || isAllow?.includes(45) ? (
                <Accordion.Item
                  eventKey="14"
                  className="mb-1"
                  onClick={() => handleClick("14")}
                >
                  <Accordion.Header>
                    <div className="me-3">
                      <FontAwesomeIcon
                        // icon="fa-regular fa-circle-question"
                        icon="fa-solid fa-circle-question"

                        // onClick={() => setIsOpen(!isOpen)}
                      />
                    </div>
                    <p
                      className="sidebar_txt"
                      style={{ display: isSidebarOpen ? "block" : "none" }}
                    >
                      FAQs
                    </p>
                  </Accordion.Header>
                  <Accordion.Body
                    className="p-0"
                    style={{
                      display: isOpen ? "block" : "none",
                    }}
                  >
                    <ul
                      className="accordion_list list-unstyled fw-normal pb-1 small"
                      // style={{
                      //   display: isSidebarOpen ? "block" : "none",
                      // }}
                    >
                      {isAllow?.includes(41) ? (
                        <Link
                          to="/settings/customization/faq-category"
                          className=" text-decoration-none  "
                          // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes(
                                    "/settings/customization/faq-category"
                                  )
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Faq Category
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}
                      {isAllow?.includes(45) ? (
                        <Link
                          to="/settings/customization/faqs"
                          className=" text-decoration-none  "
                          // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes(
                                    "/settings/customization/faqs"
                                  )
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              FAQ
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              ) : (
                <></>
              )}
              {/* ************************************************************   FAQ End ************************************************************* */}

              {/* ************************************************************  Country Codes  ************************************************************* */}
              {/* {isAllow?.includes(25) ? (
                <Accordion.Item
                  eventKey="15"
                  className="mb-1"
                  onClick={() => handleClick("15")}
                >
                  <Accordion.Header>
                    <div className="me-3">
                      <FontAwesomeIcon
                        icon="fa-solid fa-globe"

                        // onClick={() => setIsOpen(!isOpen)}
                      />
                    </div>
                    <p
                      className="sidebar_txt"
                      style={{ display: isSidebarOpen ? "block" : "none" }}
                    >
                      Country Codes
                    </p>
                  </Accordion.Header>
                  <Accordion.Body
                    className="p-0"
                    style={{
                      display: isOpen ? "block" : "none",
                    }}
                  >
                    <ul
                      className="accordion_list list-unstyled fw-normal pb-1 small"
                      // style={{
                      //   display: isSidebarOpen ? "block" : "none",
                      // }}
                    >
                      <Link
                        to="/settings/location/country-codes"
                        className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                      >
                        <li
                          className={
                            isOpen
                              ? headerText.includes(
                                  "/settings/location/country-codes"
                                )
                                ? "active block"
                                : "none"
                              : "none"
                          }
                        >
                          <p
                            className="insideDropdown_list"
                            style={{ display: isOpen ? "block" : "none" }}
                          >
                            Country Codes
                          </p>
                        </li>
                      </Link>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              ) : (
                <></>
              )} */}
              {/* ************************************************************   Country Codes End ************************************************************* */}

              {/* **************************************************************************Setting************************************************************** */}
              {/* <Accordion.Item
              eventKey="1"
              className="mb-1"
              onClick={() => handleClick("1")}
            >
              <Link to={"/settingContent"} className=" text-decoration-none">
                <Accordion.Header>
                  <div className=" me-3">
                    <FontAwesomeIcon icon="fa-solid fa-gear" />
                  </div>

                  <p
                    style={{
                      display: isSidebarOpen ? "block" : "none",
                    }}
                    className="sidebar_txt"
                  >
                    Settings
                  </p>
                </Accordion.Header>
              </Link>

              
            </Accordion.Item> */}
              {/* ************************************************************  Setting Ends ************************************************************* */}

              {/* ************************************************************  Employee Managment ************************************************************* */}
              {isAllow?.includes(1) ||
              isAllow?.includes(2) ||
              isAllow?.includes(3) ||
              isAllow?.includes(4) ||
              isAllow?.includes(5) ||
              isAllow?.includes(6) ||
              isAllow?.includes(7) ||
              isAllow?.includes(8) ? (
                <Accordion.Item
                  eventKey="16"
                  className="mb-1"
                  onClick={() => handleClick("16")}
                >
                  <Accordion.Header>
                    <div className="me-3">
                      <FontAwesomeIcon
                        icon="fa-solid fa-people-roof"
                        // onClick={() => setIsOpen(!isOpen)}
                      />
                    </div>
                    <p
                      className="sidebar_txt"
                      style={{ display: isSidebarOpen ? "block" : "none" }}
                    >
                      Employee Management
                    </p>
                  </Accordion.Header>
                  <Accordion.Body
                    className="p-0"
                    style={{
                      display: isOpen ? "block" : "none",
                    }}
                  >
                    <ul
                      className="accordion_list list-unstyled fw-normal pb-1 small"
                      // style={{
                      //   display: isSidebarOpen ? "block" : "none",
                      // }}
                    >
                      {isAllow?.includes(1) ||
                      isAllow?.includes(2) ||
                      isAllow?.includes(3) ||
                      isAllow?.includes(4) ? (
                        <Link
                          to="/employee/role"
                          className=" text-decoration-none  "
                          // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/employee/role")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Roles
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}

                      {isAllow?.includes(1) ||
                      isAllow?.includes(2) ||
                      isAllow?.includes(3) ||
                      isAllow?.includes(4) ? (
                        <Link
                          to="/employee/employee-details"
                          className=" text-decoration-none  "
                          // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes(
                                    "/employee/employee-details"
                                  )
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Employee Role Details
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}
                      {/* {isAllow?.includes(5) ||
                      isAllow?.includes(6) ||
                      isAllow?.includes(7) ||
                      isAllow?.includes(8) ? (
                      <Link
                        to="/employee/employee-details"
                        className=" text-decoration-none  "
                      // className="text-decoration-none rounded "
                      >
                        <li
                          className={
                            isOpen
                              ? headerText.includes("/employee/employee-details")
                                ? "active block"
                                : "none"
                              : "none"
                          }
                        >
                          <p
                            className="insideDropdown_list"
                            style={{ display: isOpen ? "block" : "none" }}
                          >
                            Employee Details
                          </p>
                        </li>
                      </Link>
                    ) : (
                      <></>
                    )} */}
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              ) : (
                <></>
              )}

              {/* ************************************************************   Employee Managment End ************************************************************* */}

              {isAllow?.includes(91) ||
              isAllow?.includes(95) ||
              isAllow?.includes(99) ||
              isAllow?.includes(103) ? (
                <Accordion.Item
                  eventKey="17"
                  className="mb-1"
                  onClick={() => handleClick("17")}
                >
                  <Accordion.Header>
                    <div className="me-3">
                      <FontAwesomeIcon
                        icon="fa-solid fa-gear"
                        // onClick={() => setIsOpen(!isOpen)}
                      />
                    </div>
                    <p
                      className="sidebar_txt"
                      style={{ display: isSidebarOpen ? "block" : "none" }}
                    >
                      About Us
                    </p>
                  </Accordion.Header>
                  <Accordion.Body
                    className="p-0"
                    style={{
                      display: isOpen ? "block" : "none",
                    }}
                  >
                    <ul
                      className="accordion_list list-unstyled fw-normal pb-1 small"
                      // style={{
                      //   display: isSidebarOpen ? "block" : "none",
                      // }}
                    >
                      {isAllow?.includes(91) ? (
                        <Link
                          to="/about-us/why-choose-us"
                          className=" text-decoration-none  "
                          // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/about-us/why-choose-us")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Why Choose Us
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}
                      {isAllow?.includes(95) ? (
                        <Link
                          to="/about-us/happy-customers"
                          className=" text-decoration-none  "
                          // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes(
                                    "/about-us/happy-customers"
                                  )
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Happy Customers
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}
                      {isAllow?.includes(99) ? (
                        <Link
                          to="/about-us/our-story"
                          className=" text-decoration-none  "
                          // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/about-us/our-story")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Our Story
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}
                      {isAllow?.includes(103) ? (
                        <Link
                          to="/about-us/about-banner"
                          className=" text-decoration-none  "
                          // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/about-us/about-banner")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              About Banner
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}
                      {/* ) : (
                    <></>
                  )} */}

                      {/* {isAllow?.includes(5) ||
                  isAllow?.includes(6) ||
                  isAllow?.includes(7) ||
                  isAllow?.includes(8) ? ( */}
                      {/* <Link
                    to="/employee/employee-details"
                    className=" text-decoration-none  "
                  // className="text-decoration-none rounded "
                  >
                    <li
                      className={
                        isOpen
                          ? headerText.includes("/employee/employee-details")
                            ? "active block"
                            : "none"
                          : "none"
                      }
                    >
                      <p
                        className="insideDropdown_list"
                        style={{ display: isOpen ? "block" : "none" }}
                      >
                        Employee Details
                      </p>
                    </li>
                  </Link> */}
                      {/* ) : (
                    <></>
                  )} */}
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              ) : (
                <></>
              )}
              {isAllow?.includes(213) || isAllow?.includes(282) ? (
                <Accordion.Item
                  eventKey="18"
                  className="mb-1"
                  onClick={() => handleClick("18")}
                >
                  <Accordion.Header>
                    <div className="me-3">
                      <FontAwesomeIcon
                        icon="fa-solid fa-gear"

                        // onClick={() => setIsOpen(!isOpen)}
                      />
                    </div>
                    <p
                      className="sidebar_txt"
                      style={{ display: isSidebarOpen ? "block" : "none" }}
                    >
                      Plans
                    </p>
                  </Accordion.Header>
                  <Accordion.Body
                    className="p-0"
                    style={{
                      display: isOpen ? "block" : "none",
                    }}
                  >
                    <ul
                      className="accordion_list list-unstyled fw-normal pb-1 small"
                      // style={{
                      //   display: isSidebarOpen ? "block" : "none",
                      // }}
                    >
                      {isAllow?.includes(213) ? (
                        <Link
                          to="/masters/features"
                          className=" text-decoration-none  "
                          // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/masters/features")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Membership Features
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}
                      {isAllow?.includes(282) ? (
                        <Link
                          to="/settings/membership-details"
                          className=" text-decoration-none  "
                          // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes(
                                    "/settings/membership-details"
                                  )
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Membership Details
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              ) : (
                <></>
              )}
              {isAllow?.includes(217) ? (
                <Accordion.Item
                  eventKey="19"
                  className="mb-1"
                  onClick={() => handleClick("19")}
                >
                  <Accordion.Header>
                    <div className="me-3">
                      <FontAwesomeIcon
                        icon="fa-solid fa-gear"

                        // onClick={() => setIsOpen(!isOpen)}
                      />
                    </div>
                    <p
                      className="sidebar_txt"
                      style={{ display: isSidebarOpen ? "block" : "none" }}
                    >
                      Notification
                    </p>
                  </Accordion.Header>
                  <Accordion.Body
                    className="p-0"
                    style={{
                      display: isOpen ? "block" : "none",
                    }}
                  >
                    <ul
                      className="accordion_list list-unstyled fw-normal pb-1 small"
                      // style={{
                      //   display: isSidebarOpen ? "block" : "none",
                      // }}
                    >
                      {isAllow?.includes(217) ? (
                        <Link
                          to="/settings/notification"
                          className=" text-decoration-none  "
                          // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/settings/notification")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Notification
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              ) : (
                <></>
              )}

              {isAllow?.includes(221) ||
              isAllow?.includes(222) ||
              isAllow?.includes(223) ||
              isAllow?.includes(224) ||
              isAllow?.includes(225) ||
              isAllow?.includes(226) ||
              isAllow?.includes(227) ||
              isAllow?.includes(228) ||
              isAllow?.includes(229) ||
              isAllow?.includes(230) ||
              isAllow?.includes(231) ||
              isAllow?.includes(232) ||
              isAllow?.includes(233) ||
              isAllow?.includes(234) ||
              isAllow?.includes(235) ||
              isAllow?.includes(236) ||
              isAllow?.includes(237) ||
              isAllow?.includes(238) ||
              isAllow?.includes(239) ||
              isAllow?.includes(240) ? (
                <Accordion.Item
                  eventKey="20"
                  className="mb-1"
                  onClick={() => handleClick("20")}
                >
                  <Accordion.Header>
                    <div className="me-3">
                      <FontAwesomeIcon
                        icon="fa-solid fa-gear"
                        // onClick={() => setIsOpen(!isOpen)}
                      />
                    </div>
                    <p
                      className="sidebar_txt"
                      style={{ display: isSidebarOpen ? "block" : "none" }}
                    >
                      Career Masters
                    </p>
                  </Accordion.Header>
                  <Accordion.Body
                    className="p-0"
                    style={{
                      display: isOpen ? "block" : "none",
                    }}
                  >
                    <ul
                      className="accordion_list list-unstyled fw-normal pb-1 small"
                      // style={{
                      //   display: isSidebarOpen ? "block" : "none",
                      // }}
                    >
                      {isAllow?.includes(221) ? (
                        <Link
                          to="/career/work-module"
                          className=" text-decoration-none  "
                          // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/career/work-module")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Work Module
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}
                      {isAllow?.includes(225) ? (
                        <Link
                          to="/career/job-type"
                          className=" text-decoration-none  "
                          // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/career/job-type")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Job Type
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}
                      {isAllow?.includes(229) ? (
                        <Link
                          to="/career/department"
                          className=" text-decoration-none  "
                          // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/career/department")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Department
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}
                      {isAllow?.includes(233) ? (
                        <Link
                          to="/career/qualification"
                          className=" text-decoration-none  "
                          // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/career/qualification")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Qualification
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}
                      {isAllow?.includes(237) ? (
                        <Link
                          to="/career/jobs"
                          className=" text-decoration-none  "
                          // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/career/jobs")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Jobs
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}{" "}
                      {isAllow?.includes(289) ? (
                        <Link
                          to="/employee/career/application-form"
                          className=" text-decoration-none  "
                          // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes(
                                    "/employee/career/application-form"
                                  )
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Career Application Form
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              ) : (
                <></>
              )}

              {isAllow?.includes(241) ||
              isAllow?.includes(242) ||
              isAllow?.includes(243) ||
              isAllow?.includes(244) ? (
                <Accordion.Item
                  eventKey="21"
                  className="mb-1"
                  onClick={() => handleClick("21")}
                >
                  <Accordion.Header>
                    <div className="me-3">
                      <FontAwesomeIcon
                        icon="fa-solid fa-gear"

                        // onClick={() => setIsOpen(!isOpen)}
                      />
                    </div>
                    <p
                      className="sidebar_txt"
                      style={{ display: isSidebarOpen ? "block" : "none" }}
                    >
                      Delivery Boy
                    </p>
                  </Accordion.Header>
                  <Accordion.Body
                    className="p-0"
                    style={{
                      display: isOpen ? "block" : "none",
                    }}
                  >
                    <ul
                      className="accordion_list list-unstyled fw-normal pb-1 small"
                      // style={{
                      //   display: isSidebarOpen ? "block" : "none",
                      // }}
                    >
                      {isAllow?.includes(241) ? (
                        <Link
                          to="/delivery-boy"
                          className=" text-decoration-none  "
                          // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/delivery-boy")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Profile
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              ) : (
                <></>
              )}

              {isAllow?.includes(245) ||
              isAllow?.includes(246) ||
              isAllow?.includes(247) ||
              isAllow?.includes(248) ? (
                <Accordion.Item
                  eventKey="22"
                  className="mb-1"
                  onClick={() => handleClick("22")}
                >
                  <Accordion.Header>
                    <div className="me-3">
                      <FontAwesomeIcon
                        icon="fa-solid fa-gear"

                        // onClick={() => setIsOpen(!isOpen)}
                      />
                    </div>
                    <p
                      className="sidebar_txt"
                      style={{ display: isSidebarOpen ? "block" : "none" }}
                    >
                      Supplier
                    </p>
                  </Accordion.Header>
                  <Accordion.Body
                    className="p-0"
                    style={{
                      display: isOpen ? "block" : "none",
                    }}
                  >
                    <ul
                      className="accordion_list list-unstyled fw-normal pb-1 small"
                      // style={{
                      //   display: isSidebarOpen ? "block" : "none",
                      // }}
                    >
                      {isAllow?.includes(245) ? (
                        <Link
                          to="/supplier"
                          className=" text-decoration-none  "
                          // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/supplier")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Profile
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              ) : (
                <></>
              )}

              {isAllow?.includes(249) ||
              isAllow?.includes(250) ||
              isAllow?.includes(251) ||
              isAllow?.includes(252) ? (
                <Accordion.Item
                  eventKey="23"
                  className="mb-1"
                  onClick={() => handleClick("23")}
                >
                  <Accordion.Header>
                    <div className="me-3">
                      <FontAwesomeIcon
                        icon="fa-solid fa-gear"

                        // onClick={() => setIsOpen(!isOpen)}
                      />
                    </div>
                    <p
                      className="sidebar_txt"
                      style={{ display: isSidebarOpen ? "block" : "none" }}
                    >
                      Stock Managements
                    </p>
                  </Accordion.Header>
                  <Accordion.Body
                    className="p-0"
                    style={{
                      display: isOpen ? "block" : "none",
                    }}
                  >
                    <ul
                      className="accordion_list list-unstyled fw-normal pb-1 small"
                      // style={{
                      //   display: isSidebarOpen ? "block" : "none",
                      // }}
                    >
                      {isAllow?.includes(249) ? (
                        <Link
                          to="/stock-management/order-purchase"
                          className=" text-decoration-none  "
                          // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes(
                                    "/stock-management/order-purchase"
                                  )
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Order Purchase
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              ) : (
                <></>
              )}

              {isAllow?.includes(253) ||
              isAllow?.includes(254) ||
              isAllow?.includes(255) ||
              isAllow?.includes(256) ? (
                <Accordion.Item
                  eventKey="24"
                  className="mb-1"
                  onClick={() => handleClick("24")}
                >
                  <Accordion.Header>
                    <div className="me-3">
                      <FontAwesomeIcon
                        icon="fa-solid fa-gear"

                        // onClick={() => setIsOpen(!isOpen)}
                      />
                    </div>
                    <p
                      className="sidebar_txt"
                      style={{ display: isSidebarOpen ? "block" : "none" }}
                    >
                      Finance
                    </p>
                  </Accordion.Header>
                  <Accordion.Body
                    className="p-0"
                    style={{
                      display: isOpen ? "block" : "none",
                    }}
                  >
                    <ul
                      className="accordion_list list-unstyled fw-normal pb-1 small"
                      // style={{
                      //   display: isSidebarOpen ? "block" : "none",
                      // }}
                    >
                      {isAllow?.includes(253) ? (
                        <Link
                          to="/finance"
                          className=" text-decoration-none  "
                          // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/finance")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Profile
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              ) : (
                <></>
              )}

              {isAllow?.includes(257) ? (
                <Accordion.Item
                  eventKey="25"
                  className="mb-1"
                  onClick={() => handleClick("25")}
                >
                  <Accordion.Header>
                    <div className="me-3">
                      <FontAwesomeIcon
                        icon="fa-solid fa-gear"

                        // onClick={() => setIsOpen(!isOpen)}
                      />
                    </div>
                    <p
                      className="sidebar_txt"
                      style={{ display: isSidebarOpen ? "block" : "none" }}
                    >
                      Withdrawl Request
                    </p>
                  </Accordion.Header>
                  <Accordion.Body
                    className="p-0"
                    style={{
                      display: isOpen ? "block" : "none",
                    }}
                  >
                    <ul
                      className="accordion_list list-unstyled fw-normal pb-1 small"
                      // style={{
                      //   display: isSidebarOpen ? "block" : "none",
                      // }}
                    >
                      {isAllow?.includes(257) ? (
                        <Link
                          to="/withdrawl-request"
                          className=" text-decoration-none  "
                          // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/withdrawl-request")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Withdrawl Request
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              ) : (
                <></>
              )}

              {isAllow?.includes(257) ? (
                <Accordion.Item
                  eventKey="26"
                  className="mb-1"
                  onClick={() => handleClick("26")}
                >
                  <Accordion.Header>
                    <div className="me-3">
                      <FontAwesomeIcon
                        icon="fa-solid fa-gear"

                        // onClick={() => setIsOpen(!isOpen)}
                      />
                    </div>
                    <p
                      className="sidebar_txt"
                      style={{ display: isSidebarOpen ? "block" : "none" }}
                    >
                      Report Analytics
                    </p>
                  </Accordion.Header>
                  <Accordion.Body
                    className="p-0"
                    style={{
                      display: isOpen ? "block" : "none",
                    }}
                  >
                    <ul
                      className="accordion_list list-unstyled fw-normal pb-1 small"
                      // style={{
                      //   display: isSidebarOpen ? "block" : "none",
                      // }}
                    >
                      {isAllow?.includes(274) ? (
                        <Link
                          to="/masters/report-analysis"
                          className=" text-decoration-none  "
                          // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes(
                                    "/masters/report-analysis"
                                  )
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Report Analysis
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}
                      {isAllow?.includes(257) ? (
                        <Link
                          to="/get-all-product-report"
                          className=" text-decoration-none  "
                          // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/get-all-product-report")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Product Report Analysis
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}
                      {isAllow?.includes(257) ? (
                        <Link
                          to="/all-inventry_product"
                          className=" text-decoration-none  "
                          // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/all-inventry_product")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Product Stock Report
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}

                      {isAllow?.includes(257) ? (
                        <Link
                          to="/order"
                          className=" text-decoration-none  "
                          // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/order")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Order Report
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}

                      {isAllow?.includes(257) ? (
                        <Link
                          to="/get-customer-order-report"
                          className=" text-decoration-none  "
                          // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes(
                                    "/get-customer-order-report"
                                  )
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Customer Order Report
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}

                      {isAllow?.includes(257) ? (
                        <Link
                          to="/product-graph"
                          className=" text-decoration-none  "
                          // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/product-graph")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Report In Graph
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              ) : (
                <></>
              )}
              {isAllow?.includes(257) ? (
                <Accordion.Item
                  eventKey="27"
                  className="mb-1"
                  onClick={() => handleClick("27")}
                >
                  <Accordion.Header>
                    <div className="me-3">
                      <FontAwesomeIcon
                        icon="fa-solid fa-gear"

                        // onClick={() => setIsOpen(!isOpen)}
                      />
                    </div>
                    <p
                      className="sidebar_txt"
                      style={{ display: isSidebarOpen ? "block" : "none" }}
                    >
                      Transaction Data
                    </p>
                  </Accordion.Header>
                  <Accordion.Body
                    className="p-0"
                    style={{
                      display: isOpen ? "block" : "none",
                    }}
                  >
                    <ul
                      className="accordion_list list-unstyled fw-normal pb-1 small"
                      // style={{
                      //   display: isSidebarOpen ? "block" : "none",
                      // }}
                    >
                      {isAllow?.includes(257) ? (
                        <Link
                          to="/transaction-data/subscription-history"
                          className=" text-decoration-none  "
                          // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes(
                                    "/transaction-data/subscription-history"
                                  )
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Subscription History
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}

                      {isAllow?.includes(257) ? (
                        <Link
                          to="/transaction-data/order-transaction-history"
                          className=" text-decoration-none  "
                          // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes(
                                    "/transaction-data/order-transaction-history"
                                  )
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Order Transaction History
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}

                      {/* {isAllow?.includes(257) ? (
                        <Link
                          to="/transaction-data/return-order-transaction-history"
                          className=" text-decoration-none  "
                          // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes(
                                    "/transaction-data/return-order-transaction-history"
                                  )
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Return Order Transaction History
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )} */}

                      {isAllow?.includes(257) ? (
                        <Link
                          to="/transaction-data/wallet-transaction-history"
                          className=" text-decoration-none  "
                          // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes(
                                    "/transaction-data/wallet-transaction-history"
                                  )
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Wallet Transaction History
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              ) : (
                <></>
              )}

              {isAllow?.includes(257) ? (
                <Accordion.Item
                  eventKey="28"
                  className="mb-1"
                  onClick={() => handleClick("28")}
                >
                  <Accordion.Header>
                    <div className="me-3">
                      <FontAwesomeIcon
                        icon="fa-solid fa-gear"

                        // onClick={() => setIsOpen(!isOpen)}
                      />
                    </div>
                    <p
                      className="sidebar_txt"
                      style={{ display: isSidebarOpen ? "block" : "none" }}
                    >
                      Billing Report
                    </p>
                  </Accordion.Header>
                  <Accordion.Body
                    className="p-0"
                    style={{
                      display: isOpen ? "block" : "none",
                    }}
                  >
                    <ul
                      className="accordion_list list-unstyled fw-normal pb-1 small"
                      // style={{
                      //   display: isSidebarOpen ? "block" : "none",
                      // }}
                    >
                      {isAllow?.includes(257) ? (
                        <Link
                          to="/billing/customer-billing-report"
                          className=" text-decoration-none  "
                          // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes(
                                    "/billing/customer-billing-report"
                                  )
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Customer Billing Report
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}

                      {isAllow?.includes(257) ? (
                        <Link
                          to="/billing/vendor-billing-report"
                          className=" text-decoration-none  "
                          // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes(
                                    "/billing/vendor-billing-report"
                                  )
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Vendor Billing Report
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              ) : (
                <></>
              )}

              {isAllow?.includes(257) ? (
                <Accordion.Item
                  eventKey="29"
                  className="mb-1"
                  onClick={() => handleClick("29")}
                >
                  <Accordion.Header>
                    <div className="me-3">
                      <FontAwesomeIcon
                        icon="fa-solid fa-gear"

                        // onClick={() => setIsOpen(!isOpen)}
                      />
                    </div>
                    <p
                      className="sidebar_txt"
                      style={{ display: isSidebarOpen ? "block" : "none" }}
                    >
                      Utilities Report
                    </p>
                  </Accordion.Header>
                  <Accordion.Body
                    className="p-0"
                    style={{
                      display: isOpen ? "block" : "none",
                    }}
                  >
                    <ul
                      className="accordion_list list-unstyled fw-normal pb-1 small"
                      // style={{
                      //   display: isSidebarOpen ? "block" : "none",
                      // }}
                    >
                      {/* {isAllow?.includes(288) ? (
                        <Link
                          to="/utilities/driver-transaction"
                          className=" text-decoration-none  "
                          // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes(
                                    "/utilities/driver-transaction"
                                  )
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Delivery Boy Transaction
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )} */}

                      {isAllow?.includes(288) ? (
                        <Link
                          to="/seller-detail"
                          className=" text-decoration-none  "
                          // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/seller-detail")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Accepted Seller Data
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}

                      {isAllow?.includes(288) ? (
                        <Link
                          to="/employee/info-subscriber"
                          className=" text-decoration-none  "
                          // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes(
                                    "/employee/info-subscriber"
                                  )
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              News Letter Subscriber
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}

                      {isAllow?.includes(270) ? (
                        <Link
                          to="/advertisement-banner"
                          className=" text-decoration-none  "
                          // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/advertisement-banner")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Tablet Video
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              ) : (
                <></>
              )}
            </Accordion>
          </ul>
        </div>
      </section>
    </>
  );
};

export default Sidebar;
