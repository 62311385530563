import React, { useContext, useState, useEffect } from "react";
import "../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import { Context } from "../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import ModalSave from "../common/ModelSave";
import { CancelButton, SaveButton } from "../common/Button";
import { Row, Col, Form, InputGroup, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import { FaStar } from "react-icons/fa";

library.add(fas);

const RatingOffCanvance = (props) => {
  const id = props.show;
  const { postData, getData } = useContext(Context);
  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const [data, setData] = useState({});
  const [starRating, setStarRating] = useState(0);

  const GetEditData = async () => {
    const response = await getData(`/employee/seller/${id}`);
    reset(response?.data);
    setData(response?.data);
    setCommission(response?.data?.commission);
  };

  useEffect(() => {
    GetEditData();
  }, []);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm();

  const [commission, setCommission] = useState();
  const ZeroCommission = commission === undefined ? 0 : commission;

  const handleCommissionChange = (e) => {
    const value = e.target.value;
    setCommission(value);
    setValue("commission", value);
  };

  const handleStarClick = (rating) => {
    setStarRating(rating);
    setValue("store_rating", rating); // Update the form value
  };

  const onSubmit = async (data) => {
    try {
      const d = new FormData();
      d.append("store_rating", starRating);

      const response = await postData(`/employee/seller/rating/${id}`, d);

      if (response?.success) {
        await setShowModal({ code: response.code, message: response.message });
      } else {
        await setShowModal({ code: response.code, message: response.message });
      }

      setTimeout(() => {
        setShowModal(0);
        props.handleClose();
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Modal
        {...props}
        onHide={props.handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit {data?.first_name}'s Store Rating
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(onSubmit)} role="form">
            <Row className="justify-content-center">
              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="mt-3">
                    <Form.Group>
                      <div className="text-center">
                        <Form.Label>Store Rating</Form.Label>
                        <div>
                          {[1, 2, 3, 4, 5].map((star) => (
                            <FaStar
                              key={star}
                              size={30}
                              color={star <= starRating ? "gold" : "gray"}
                              onClick={() => handleStarClick(star)}
                              style={{ cursor: "pointer" }}
                            />
                          ))}
                        </div>
                      </div>
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Row className="mt-5 pb-3">
                <div className="d-flex justify-content-center">
                  <Link>
                    <CancelButton
                      name={"cancel"}
                      handleClose={props.handleClose}
                    />
                  </Link>

                  <SaveButton
                    name={"save"}
                    handleSubmit={handleSubmit(onSubmit)}
                  />
                </div>
              </Row>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default RatingOffCanvance;
